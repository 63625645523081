body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 58px;
}

#container {
  position: relative;
  min-height: 100vh;
}

.em-l-container {
  margin: 0px auto !important;
}

.icon-right {
  float: right;
}

.btn--right {
  float: right !important;
}

.logged-out .em-c-hero,
.logged-out .em-c-hero__body
 {
  min-height: 70vh;
}

.logged-out .em-c-footer {
  margin-top: 0;
  border-top: none;
}

.em-c-toolbar {
  padding: 2em .4em !important;
  border-top: none !important;
}

.em-c-hero__body {
  margin: 0px auto !important;
  padding-top: 150px !important;
  padding-bottom: 150px !important;
  background: none !important;
  background-image: url("layout/images/tfp-hero.jpg") !important; 
  background-repeat: no-repeat !important;
  background-position: center 75% !important;
  background-size: cover !important;
}

.em-c-hero__title {
  font-weight: 600 !important;
}

.em-c-toolbar__blue {
  background: #002F6C !important;
}

.em-l-grid__item {
  align-items: center;
}
.em-c-solid-card {
  max-width: 38rem !important;
  border-radius: 0 !important;
}

.border-success {
  border-top: 8px solid #00af53;
  border-radius: 0 !important;
}

.border-warning {
  border-top: 8px solid #ed8b00;
  border-radius: 0 !important;
}

.border-error {
  border-top: 8px solid #d82424;
  border-radius: 0 !important;
}

.fpo-block {
  width: 100%;
}

.text-bold {
  font-weight: bolder;
}

.text-indigo {
  color: #002F6C;
}

.text-red {
  color: #d82424;
}

.text-blue-curious {
  color: #3190d9
}

.icon-success {
  color: #00af53;
  margin: 0px 5px 0 0;
}

.icon-warning {
  color: #ed8b00;
  margin: 0px 5px 0 0;
}

.text-card {
  padding-left: 20px;
}

.icon-errror {
  color: #d82424;
  margin: 0px 5px 0 0;
}

.em-c-tree__link {
  color: black !important;
}

.em-c-solid-card__footer {
  padding: 1rem !important;
  border-radius: 0 !important;
}

.em-c-solid-card__footer,
.em-c-solid-card__footer a {
  color: #002F6C;
  text-decoration: none;
}

.em-l__main::-webkit-scrollbar {
  display: none;
}


.em-c-solid-card__footer a {
  margin-left: 1rem;
}

.em-c-solid-card__footer:hover,
.em-c-solid-card__footer:hover a {
  background-color: #002F6C;
  color: white;
  cursor: pointer;
}

.em-c-header__blue {
  background-color: #d7d7d7;
  padding: 0 15px;
  color: black;
}

.em-c-header__title-logo {
  color: black !important
}

nav.breadcrumb {
  padding: 50px;
}

.em-c-tree {
  margin: 0 !important
}
.em-c-tree__item {
  background: white !important;
}
.em-c-tree__link:hover {
  background-color: #90caf6 !important;
}
.em-c-tree__link {
  text-decoration: none !important;
}
.em-c-tree__icon {
  color: black !important;
}

.em-c-loader {
  width: 10vw;
    margin: 20vw auto;
}

.collapsible .content {
  background-color: #fff;
  padding: 15px;
  border: 2px solid #ececec;
  border-top: 0 !important;
  margin-bottom: 50px;
}
.collapsible .header {
  background-color: #f5f5f5;
  padding: 15px 10px;
  box-shadow: inset 0px -5px 5px -5px black;
}
.collapsible--main {
  padding: 10px 5px;
}
.collapsible--blue {
  background-color: #cee1ef !important;
  color: #002f6c !important;
}

.em-c-table-object .em-c-table {
  background-color: white !important;
}

.em-c-table__header {
  background: white !important;
  color: #0c69b0 !important;
  font-weight: bolder !important;
}

.em-c-table__header tr th{
  border-right: 1px solid #0c69b0;
  border-left: 1px solid #0c69b0;
  font-weight: bolder !important;
}

.em-c-table__header tr th:last-child, .em-c-table__header tr th:first-child{
  border: none !important;
  font-weight: bolder !important;
}

.modal__content {
  margin: 32px;
  overflow: auto;
  max-height: 50vh;
}

.em-c-modal__footer .em-c-btn-group  {
  float: right;
}

.button--blue {
  color: #0c69b0;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.collapsible .append {
  float: right;
  margin-right: 15px;
}

.chart {
  width: 400px;
  height: 400px;
}
.icon--danger {
  color:#d82424;
}
.icon--warning {
  color: #ed8b00;
}
.icon--success {
  color: #00af53;
}

.icon__status {
  margin: 0 5px;
}

.MuiStack-root.css-1xhypcz-MuiStack-root {
  padding: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.em-c-select {
  width: 100%;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.date-picker {

}

.em-c-btn-bar__item {
  z-index: 0;
}

.react-tabs__tab-list {
  margin: 0 !important;
  border-bottom: 1px solid #e5e5e5;
}

.react-tabs__tab--selected {
  border-color: #e5e5e5;
}

.warning-line {
  padding-top: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.warning-line h2 {
  font-size: 1.5em;
}

#phone-input {
  display: flex;
  gap: 10px;
}

.phone-cc {
  flex: 1;
}

.phone-tel {
  flex: 8;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  display:none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  height: 7px !important;
  font-color: hsl(0, 0%, 20%);
}

.box-bol {
  gap: 20px !important;
}

#button-modal {
  margin-top: -3px !important;
}

#index-button {
  z-index: 0 !important;
}

#test-animated-2-u-red_ts {animation: test-animated-2-u-red_ts__ts 2000ms linear infinite normal forwards}@keyframes test-animated-2-u-red_ts__ts { 0% {transform: translate(50px,50px) scale(0,0);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 25% {transform: translate(50px,50px) scale(1,1)} 75% {transform: translate(50px,50px) scale(1,1)} 100% {transform: translate(50px,50px) scale(0,0)}} #test-animated-2-u-white_ts {animation: test-animated-2-u-white_ts__ts 2000ms linear infinite normal forwards}@keyframes test-animated-2-u-white_ts__ts { 0% {transform: translate(50px,50px) scale(0,0)} 10% {transform: translate(50px,50px) scale(0,0);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 35% {transform: translate(50px,50px) scale(1,1);animation-timing-function: cubic-bezier(0.428594,0,0.69925,0.552652)} 65% {transform: translate(50px,50px) scale(1,1);animation-timing-function: cubic-bezier(0.286277,0.543492,0.592031,1)} 90% {transform: translate(50px,50px) scale(0,0)} 100% {transform: translate(50px,50px) scale(0,0)}} #test-animated-2-u-blue_ts {animation: test-animated-2-u-blue_ts__ts 2000ms linear infinite normal forwards}@keyframes test-animated-2-u-blue_ts__ts { 0% {transform: translate(50px,50px) scale(0,0)} 20% {transform: translate(50px,50px) scale(0,0);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 45% {transform: translate(50px,50px) scale(1,1);animation-timing-function: cubic-bezier(0.369483,0,0.697901,0.378654)} 55% {transform: translate(50px,50px) scale(1,1);animation-timing-function: cubic-bezier(0.376037,0.220878,0.650127,0.710224)} 80% {transform: translate(50px,50px) scale(0,0)} 100% {transform: translate(50px,50px) scale(0,0)}}


.successResult {
  font-size: 1.5em;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: hsl(0, 0%, 70%) !important;
  width: 100%;
}

.mx-auto {
  margin: auto auto !important;
}

.container-space {
  max-width: 70vw !important;
  margin: auto auto;
}

.alignment {
  padding-left: 25px;
}

.ReactModalPortal {
  z-index: 9;
}

.pagination-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pagination {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  gap: 20px;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 9999999 !important;
}

.create-user-margin {
  margin-bottom: 100px;
}

.layout-header {
  width: 100%;
  padding: 0 58px !important; 
  margin: 0 !important;
}

.search-terminal {
  padding: 20px 58px !important;
}

.header-body {
  display: flex; 
  justify-content: space-between;
}

.header-link {
  width: 170px; 
  display: inline-block;
}

.header-nav {
  margin: 0;
  justify-content: normal;
}

.hero-title {
  padding: 5rem 58px;
}

.loader-area {
  margin: 128px auto 256px auto;
}

.sidebar-description {
  margin-top: 25px;
}

.loader-area-limiter {
  height: 100px;
  position: relative;
  bottom: 100px;
}

.bol-overflow {
  overflow: auto;
}

.headerDropDownHide {
  height: 0;
  width: 0;
}

.headerDropDown {
  margin-Left: 16px; 
  min-width: 100px;
  border-radius: 5px;
}

.headerDropDownItem, .headerDropDownItemActive {
  background-Color: #3477bd;
  text-Align: center;
  transition: all 0.5s ease;
  height: 25px;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.headerDropDownItemActive {
  text-decoration: underline;    
}

.headerDropDownItem:hover, .headerDropDownItemActive:hover {
  background-Color: #3190d9;
}

.headerBtn {
  cursor: pointer;
} 
  
.select-products {
  z-index: -1 !important;
  margin: 0 0 10px 0 !important;
}

.Feedback-Modal {
  background-color: #f5f5f5;
  border-radius: 2vw;
  position: fixed;
  left: 30vw;
  top: 20%;
  width: 40%;
  height: fit-content;
  min-height: 40vh;
  max-height: 70vh;
  z-index: 50;
  padding: 3%;
}
.Feedback-Modal-BackGround {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 45;
  background: rgba(0, 0, 0, .4);
  overflow: auto;
  transition: all 2s ease;
  cursor: pointer;
}
.Feedback-Header {
  display: flex;
  justify-content: space-between;
}
.Feedback-Title {
  color: black;
  font-size: min(4vh, 5vw);
  font-weight: bold;
  margin-bottom: 1vh;
}
.Feedback-Close-Icon > svg {
  height: 4vh;
  width: 2vw;
  min-width: 4vh;
  cursor: pointer;
  transition: all 0.3s ease;
}
.Feedback-Close-Icon > svg:hover {
  fill: #b6b6b6 !important;
}
.Feedback-Input {
  height: 40vh;
  width: 100%;
  font-size: 3vh;
  resize: none;
  padding: 1vh 1vw;
}
.Feedback-Footer {
  display: flex;
  justify-content: flex-end;
}
.Feedback-Submit-Button {
  width: 8vw;
  min-width: 12vh;
  height: 5vh;
  border-radius: 1vh;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cee1ef;
  cursor: pointer;
  color: black;
  font-weight: bold;
  font-size: 2vh;
  transition: all 0.3s ease;
}
.Feedback-Submit-Button-disable {
  width: 8vw;
  min-width: 12vh;
  height: 5vh;
  border-radius: 1vh;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cee1ef;
  cursor: pointer;
  color: black;
  font-weight: bold;
  font-size: 2vh;
  transition: all 0.3s ease;
  pointer-events: none;
}
.Feedback-Submit-Button:hover {
  background-color: #9dc8e7;
  color: #FFF;
}
.Feedback-Submit-Icon {
  color: #FFF;
  height: 4vh;
  width: 2vw;
  min-width: 4vh;
}
.Feedback-Submit-message {
  font-size: min(4vh, 5vw);
  height: 25vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  color: black;
}
.Feedback-error {
  width: 70%;
  min-height: 5vh;
  padding: 1vh 1vw;
  margin: 1vh 1vw;
  font-size: min(2vh, 3vw);;
  background-color: red;
}